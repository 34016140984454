pre code.hljs {
  padding: 1em;
  display: block;
  overflow-x: auto;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  color: #383a42;
  background: #fafafa;
}

.hljs-comment, .hljs-quote {
  color: #a0a1a7;
  font-style: italic;
}

.hljs-doctag, .hljs-formula, .hljs-keyword {
  color: #a626a4;
}

.hljs-deletion, .hljs-name, .hljs-section, .hljs-selector-tag, .hljs-subst {
  color: #e45649;
}

.hljs-literal {
  color: #0184bb;
}

.hljs-addition, .hljs-attribute, .hljs-meta .hljs-string, .hljs-regexp, .hljs-string {
  color: #50a14f;
}

.hljs-attr, .hljs-number, .hljs-selector-attr, .hljs-selector-class, .hljs-selector-pseudo, .hljs-template-variable, .hljs-type, .hljs-variable {
  color: #986801;
}

.hljs-bullet, .hljs-link, .hljs-meta, .hljs-selector-id, .hljs-symbol, .hljs-title {
  color: #4078f2;
}

.hljs-built_in, .hljs-class .hljs-title, .hljs-title.class_ {
  color: #c18401;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}

.hljs-link {
  text-decoration: underline;
}

/*# sourceMappingURL=index.3b071f01.css.map */
